import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			chapters: [],
			deviceType: 'mobile',
			domainConfig: null,
			adsensConfig: {},
			ads: {
				ad1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '6955171686',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				ad2: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '3015926678',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				ad3: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '6252464109',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				ad4: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '8486140542',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				ad5: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '4939382434',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				ad6: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-8158555231596181',
					'data-ad-slot': '6929516295',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
			},
			showDebug: false,
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
			setChapters(state, chapters) {
				state.chapters = chapters
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			domainConfig: (state) => state.domainConfig,
			ads: (state) => state.ads,
		},
	})
}
