<template>
  <CommonPage>
    <div class="indexContent">
      <div class="banner flex aic" :style="{ background: `url(${imgUrl})` }" @click="flash">
        <div class="son">
          <div class="top">
            Daily Quote
          </div>
          <div class="middle">
            <div class="mt">
              {{ quoteAla }}
            </div>
          </div>
          <div class="bottom">
            <div>{{ quoteEng }}</div>
            <div>{{ posi }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <Ad :ads="adsensConfig.ad1" class="span2" />
        <div class="qg">
          {{ domainConfig['title'] }}
        </div>

        <div class="chapters">
          <div v-for="(item, index) in chapters" :key="item.transliteratedName"
            class="item flex aic" @click="selectChapter(item.transliteratedName)">
            <div class="left">
              {{ index + 1 }}
            </div>
            <div class="right flex f1 sb aic">
              <div class="left">
                <div class="top">
                  {{ item.transliteratedName }}
                </div>
                <div class="bottom">
                  {{ item.translatedName }}
                </div>
              </div>
              <div class="right">
                <div class="top">
                  {{ String(index + 1).padStart(3, '0') }}
                </div>
                <div class="bottom">
                  {{ item.versesCount }} Ayahs
                </div>
              </div>
            </div>
          </div>
          <Ad :posi="{
							pc: {
								colNum: 2,
								rowAt: 3,
							},
							m: 4,
						}" :ads="adsensConfig.ad2" />
          <Ad :posi="{
							pc: {
								colNum: 2,
								rowAt: 5,
							},
							m: 8,
						}" :ads="adsensConfig.ad3" />
          <Ad :posi="{
							pc: {
								colNum: 2,
								rowAt: 8,
							},
							m: 12,
						}" :ads="adsensConfig.ad4" />
        </div>
      </div>

      <!-- (index === 8 && deviceType === 'mobile') || (index === 9 && deviceType === 'desktop') -->
    </div>
  </CommonPage>
</template>
<script>
const di = {
  mobile: 'm',
  desktop: 'p',
}
const dic = [
  {
    quoteAla: 'فَجَعَلَهُمْ كَعَصْفٍۢ مَّأْكُولٍۭ',
    posi: '(105:5)',
    quoteEng: 'Leaving them like chewed-up leaves.',
  },
  {
    quoteAla: 'أَلَمْ يَجِدْكَ يَتِيمًۭا فَـَٔاوَىٰ',
    posi: '(93:6)',
    quoteEng: 'Did He not find you orphaned, and sheltered you?',
  },
  {
    quoteAla: 'فَوَيْلٌۭ لِّلْمُصَلِّينَ',
    posi: '(107:4)',
    quoteEng: 'So woe to those who pray.',
  },
  {
    quoteAla: 'ذُو ٱلْعَرْشِ ٱلْمَجِيدُ',
    posi: '(85:15)',
    quoteEng: 'Possessor of the Glorious Throne.',
  },
  {
    quoteAla: 'لِّلطَّٰغِينَ مَـَٔابًۭا',
    posi: '(78:22)',
    quoteEng: 'For the oppressors, a destination.',
  },
  {
    quoteAla: 'إِنَّ بَطْشَ رَبِّكَ لَشَدِيدٌ',
    posi: '(85:12)',
    quoteEng: 'The onslaught of your Lord is severe.',
  },
  {
    quoteAla: 'فَوَيْلٌۭ لِّلْمُصَلِّينَ',
    posi: '(107:4)',
    quoteEng: 'So woe to those who pray.',
  },
  {
    quoteAla: 'وَيَمْنَعُونَ ٱلْمَاعُونَ',
    posi: '(107:7)',
    quoteEng: 'And withhold the assistance.',
  },
  {
    quoteAla: 'وَإِلَى ٱلْجِبَالِ كَيْفَ نُصِبَتْ',
    posi: '(88:19)',
    quoteEng: 'And at the mountains-how they are installed?',
  },
  {
    quoteAla: 'وَيْلٌۭ لِّكُلِّ هُمَزَةٍۢ لُّمَزَةٍ',
    posi: '(104:1)',
    quoteEng: 'Woe to every slanderer backbiter.',
  },
  {
    quoteAla: 'إِنَّ ٱلْإِنسَٰنَ لَفِى خُسْرٍ',
    posi: '(103:2)',
    quoteEng: 'The human being is in loss.',
  },
  {
    quoteAla: 'لَقَدْ رَأَىٰ مِنْ ءَايَٰتِ رَبِّهِ ٱلْكُبْرَىٰٓ',
    posi: '(53:18)',
    quoteEng: 'He saw some of the Great Signs of his Lord.',
  },
  {
    quoteAla: 'فَبِأَىِّ ءَالَآءِ رَبِّكَ تَتَمَارَىٰ',
    posi: '(53:55)',
    quoteEng: "So which of your Lord's marvels can you deny?",
  },
  {
    quoteAla: 'وَيَتَجَنَّبُهَا ٱلْأَشْقَى',
    posi: '(87:11)',
    quoteEng: 'But the wretched will avoid it.',
  },
  {
    quoteAla: 'فَمَا لَهُۥ مِن قُوَّةٍۢ وَلَا نَاصِرٍۢ',
    posi: '(86:10)',
    quoteEng: 'He will have no strength, and no supporter.',
  },
  {
    quoteAla: 'قَوَارِيرَا۟ مِن فِضَّةٍۢ قَدَّرُوهَا تَقْدِيرًۭا',
    posi: '(76:16)',
    quoteEng: 'Crystal of silver-they measured them exactly.',
  },
  {
    quoteAla: 'وَٱلْأَرْضِ ذَاتِ ٱلصَّدْعِ',
    posi: '(86:12)',
    quoteEng: 'And the earth that cracks open.',
  },
  {
    quoteAla: 'وَإِنَّهُۥ لِحُبِّ ٱلْخَيْرِ لَشَدِيدٌ',
    posi: '(100:8)',
    quoteEng: 'And he is fierce in his love of wealth.',
  },
  {
    quoteAla: 'فَأَمَّا مَنْ أَعْطَىٰ وَٱتَّقَىٰ',
    posi: '(92:5)',
    quoteEng: 'As for him who gives and is righteous.',
  },
  {
    quoteAla: 'مَا ٱلْقَارِعَةُ',
    posi: '(101:2)',
    quoteEng: 'What is the Shocker?',
  },
  {
    quoteAla: 'فَجَعَلَهُمْ كَعَصْفٍۢ مَّأْكُولٍۭ',
    posi: '(105:5)',
    quoteEng: 'Leaving them like chewed-up leaves.',
  },
  {
    quoteAla: 'أَحْيَآءًۭ وَأَمْوَٰتًۭا',
    posi: '(77:26)',
    quoteEng: 'For the living and the dead?',
  },
  {
    quoteAla: 'أَتَبْنُونَ بِكُلِّ رِيعٍ ءَايَةًۭ تَعْبَثُونَ',
    posi: '(26:128)',
    quoteEng: "Do you build a monument on every height for vanity's sake?",
  },
  {
    quoteAla: 'مَلِكِ ٱلنَّاسِ',
    posi: '(114:2)',
    quoteEng: 'The King of mankind.',
  },
  {
    quoteAla: 'فَٱلتَّٰلِيَٰتِ ذِكْرًا',
    posi: '(37:3)',
    quoteEng: 'And the reciters of the Reminder.',
  },
  {
    quoteAla: 'وَرَفَعْنَا لَكَ ذِكْرَكَ',
    posi: '(94:4)',
    quoteEng: 'And raised for you your reputation?',
  },
  {
    quoteAla: 'بَلْ هُوَ قُرْءَانٌۭ مَّجِيدٌۭ',
    posi: '(85:21)',
    quoteEng: 'In fact, it is a Glorious Quran.',
  },
  {
    quoteAla: 'تَبَّتْ يَدَآ أَبِى لَهَبٍۢ وَتَبَّ',
    posi: '(111:1)',
    quoteEng: 'Condemned are the hands of Abee Lahab, and he is condemned.',
  },
  {
    quoteAla: 'ٱلَّتِى لَمْ يُخْلَقْ مِثْلُهَا فِى ٱلْبِلَٰدِ',
    posi: '(89:8)',
    quoteEng: 'The like of which was never created in the land.',
  },
  {
    quoteAla: 'وَسُيِّرَتِ ٱلْجِبَالُ فَكَانَتْ سَرَابًا',
    posi: '(78:20)',
    quoteEng: 'And the mountains are set in motion, and become a mirage.',
  },
  {
    quoteAla: 'وَإِلَى ٱلسَّمَآءِ كَيْفَ رُفِعَتْ',
    posi: '(88:18)',
    quoteEng: 'And at the sky-how it is raised?',
  },
  {
    quoteAla: 'إِنَّ رَبَّهُم بِهِمْ يَوْمَئِذٍۢ لَّخَبِيرٌۢ',
    posi: '(100:11)',
    quoteEng: 'Their Lord, on that Day, is fully informed of them.',
  },
  {
    quoteAla: 'إِنَّآ أَعْطَيْنَٰكَ ٱلْكَوْثَرَ',
    posi: '(108:1)',
    quoteEng: 'We have given you plenty.',
  },
  {
    quoteAla: 'ٱلَّذِىٓ أَنقَضَ ظَهْرَكَ',
    posi: '(94:3)',
    quoteEng: 'Which weighed down your back?',
  },
  {
    quoteAla: 'فَلْيَنظُرِ ٱلْإِنسَٰنُ إِلَىٰ طَعَامِهِۦٓ',
    posi: '(80:24)',
    quoteEng: 'Let man consider his food.',
  },
  {
    quoteAla: 'وَإِنَّهُۥ عَلَىٰ ذَٰلِكَ لَشَهِيدٌۭ',
    posi: '(100:7)',
    quoteEng: 'And he bears witness to that.',
  },
  {
    quoteAla: 'إِنَّ هَٰذَا لَفِى ٱلصُّحُفِ ٱلْأُولَىٰ',
    posi: '(87:18)',
    quoteEng: 'This is in the former scriptures.',
  },
  {
    quoteAla: 'وَثَمُودَ ٱلَّذِينَ جَابُوا۟ ٱلصَّخْرَ بِٱلْوَادِ',
    posi: '(89:9)',
    quoteEng: 'And Thamood-those who carved the rocks in the valley.',
  },
  {
    quoteAla: 'وَٱلْيَوْمِ ٱلْمَوْعُودِ',
    posi: '(85:2)',
    quoteEng: 'And by the Promised Day.',
  },
  {
    quoteAla: 'يَقُولُ يَٰلَيْتَنِى قَدَّمْتُ لِحَيَاتِى',
    posi: '(89:24)',
    quoteEng: 'He will say, "If only I had forwarded for my life."',
  },
  {
    quoteAla: 'وَٱلنَّٰشِطَٰتِ نَشْطًۭا',
    posi: '(79:2)',
    quoteEng: 'And those who remove gently.',
  },
  {
    quoteAla: 'لَكُمْ دِينُكُمْ وَلِىَ دِينِ',
    posi: '(109:6)',
    quoteEng: 'You have your way, and I have my way."',
  },
  {
    quoteAla: 'ٱهْدِنَا ٱلصِّرَٰطَ ٱلْمُسْتَقِيمَ',
    posi: '(1:6)',
    quoteEng: 'Guide us to the straight path.',
  },
  {
    quoteAla: 'فَسَجَدَ ٱلْمَلَٰٓئِكَةُ كُلُّهُمْ أَجْمَعُونَ',
    posi: '(38:73)',
    quoteEng: 'So the angels fell prostrate, all of them.',
  },
  {
    quoteAla: 'وَمَآ أَدْرَىٰكَ مَا هِيَهْ',
    posi: '(101:10)',
    quoteEng: 'Do you know what it is?',
  },
  {
    quoteAla: 'صُحُفِ إِبْرَٰهِيمَ وَمُوسَىٰ',
    posi: '(87:19)',
    quoteEng: 'The Scriptures of Abraham and Moses.',
  },
  {
    quoteAla: 'مَا زَاغَ ٱلْبَصَرُ وَمَا طَغَىٰ',
    posi: '(53:17)',
    quoteEng: 'The sight did not waver, nor did it exceed.',
  },
  {
    quoteAla: 'فَٱلْمُورِيَٰتِ قَدْحًۭا',
    posi: '(100:2)',
    quoteEng: 'Igniting sparks.',
  },
  {
    quoteAla: 'لَيْلَةُ ٱلْقَدْرِ خَيْرٌۭ مِّنْ أَلْفِ شَهْرٍۢ',
    posi: '(97:3)',
    quoteEng: 'The Night of Decree is better than a thousand months.',
  },
  {
    quoteAla: 'كَذَّبَتْ ثَمُودُ بِطَغْوَىٰهَآ',
    posi: '(91:11)',
    quoteEng: 'Thamood denied in its pride.',
  },
]
import '@/css/index.scss'
export default {
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          name: 'title',
          content: this.title,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    }
  },
  data () {
    return {
      imgUrl: '',
      quoteAla: '',
      posi: '',
      quoteEng: '',
      chapters: [],
      hasInitChaptersFromServer: false,
    }
  },
  computed: {
    title () {
      return `${this.domainConfig['title']} - Complete Quran Chapters List`
    },
    description () {
      return 'Explore the complete list of Quran chapters. Start your journey through the Holy Quran with our comprehensive chapter listing.'
    },
  },
  created () {
    if (this.$store.state.chapters.length > 0) this.hasInitChaptersFromServer = true
    this.chapters = this.$store.state.chapters
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`)
    document.querySelector('body').style.backgroundColor = '#131518'
    await this.loadAdSenseScript() // 重新加载广告脚本
  },
  beforeMount () {
    this.imgUrl = require(`@/assets/${di[this.deviceType]}${Math.floor(Math.random() * 4) + 1}.webp`)
    let n = Math.floor(Math.random() * 48) + 1
    this.posi = dic[n].posi
    this.quoteAla = dic[n].quoteAla
    this.quoteEng = dic[n].quoteEng
  },
  async mounted () {
    if (!this.hasInitChaptersFromServer) {
      const obj = require('../quran/chapters.json')
      this.chapters = Object.keys(obj).map((e) => obj[e])
    }
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
    // await this.loadAdSenseScript() // 重新加载广告脚本
  },

  methods: {
    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互end
    selectChapter (transliteratedName) {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query
      const channel = currentParams.channel; //获取 channel

      const query = { ...currentQuery }; //构造 query

      const params = { chapter: transliteratedName }; //构造 params
      const routeName = channel ? 'detailchannel' : 'detail'; //是否存在 channel 动态选择路由
      this.$router.push({ name: routeName, params, query });
      // this.$router.push({
      //   name: 'detail',
      //   params: {
      //     chapter: transliteratedName,
      //   },
      // })
    },
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) {
        // 检查广告是否已经加载
        console.log('Adsense script already loaded.')
        this.$eventrack('adscript_loaded', 'expose')
        this.loadAdWithDelay()
        return // 如果已加载，直接返回
      }
      await this.loadScriptConditionally()
    },
    loadScriptConditionally () {
      // console.log(this.adsensConfig)
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链')
        this.$eventrack('no_adscript_config', 'expose')
        return
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose')
        console.log('脚本已存在，无需重新添加')
        return
      }

      console.log('准备插入脚本')
      const script = document.createElement('script')
      script.src = this.adsensConfig?.scriptUrl
      script.crossOrigin = 'anonymous'
      script.async = true

      const header = document.getElementsByTagName('head')[0]
      header.appendChild(script)

      this.$eventrack('adscript_add_success', 'expose')
      script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd()
      }, 500)
    },
    async displayAd () {
      await this.$nextTick()

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ad')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref) // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log('Adsense script not loaded yet, delaying ad display.')
        setTimeout(this.displayAd, 500) // 延迟再次尝试
        return
      }

      console.log(adsElements) // 检查是否包含 <ins> 标签
      adsElements.forEach((ad) => {
        console.log(ad) // 输出每个广告元素
        console.log('ready to push')
          ; (window.adsbygoogle = window.adsbygoogle || []).push({})
      })
    },
    flash () {
      if (this.deviceType === 'mobile') location.href = '/'
    },
  },
}
</script>