var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CommonPage',[_c('div',{staticClass:"indexContent"},[_c('div',{staticClass:"banner flex aic",style:({ background: `url(${_vm.imgUrl})` }),on:{"click":_vm.flash}},[_c('div',{staticClass:"son"},[_c('div',{staticClass:"top"},[_vm._v(" Daily Quote ")]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"mt"},[_vm._v(" "+_vm._s(_vm.quoteAla)+" ")])]),_c('div',{staticClass:"bottom"},[_c('div',[_vm._v(_vm._s(_vm.quoteEng))]),_c('div',[_vm._v(_vm._s(_vm.posi))])])])]),_c('div',{staticClass:"content"},[_c('Ad',{staticClass:"span2",attrs:{"ads":_vm.adsensConfig.ad1}}),_c('div',{staticClass:"qg"},[_vm._v(" "+_vm._s(_vm.domainConfig['title'])+" ")]),_c('div',{staticClass:"chapters"},[_vm._l((_vm.chapters),function(item,index){return _c('div',{key:item.transliteratedName,staticClass:"item flex aic",on:{"click":function($event){return _vm.selectChapter(item.transliteratedName)}}},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"right flex f1 sb aic"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"top"},[_vm._v(" "+_vm._s(item.transliteratedName)+" ")]),_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(item.translatedName)+" ")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[_vm._v(" "+_vm._s(String(index + 1).padStart(3, '0'))+" ")]),_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(item.versesCount)+" Ayahs ")])])])])}),_c('Ad',{attrs:{"posi":{
							pc: {
								colNum: 2,
								rowAt: 3,
							},
							m: 4,
						},"ads":_vm.adsensConfig.ad2}}),_c('Ad',{attrs:{"posi":{
							pc: {
								colNum: 2,
								rowAt: 5,
							},
							m: 8,
						},"ads":_vm.adsensConfig.ad3}}),_c('Ad',{attrs:{"posi":{
							pc: {
								colNum: 2,
								rowAt: 8,
							},
							m: 12,
						},"ads":_vm.adsensConfig.ad4}})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }